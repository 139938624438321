var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 pa-0 fill-height"},[_c('v-col',{staticClass:"d-flex align-center mx-auto fill-height",attrs:{"cols":"10","lg":"5","xl":"3"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-8",attrs:{"width":"100%"}},[_c('v-card-title',{staticClass:"d-flex flex-column font-weight-bold"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"MegaBusiness Logo","max-width":"206.36"}})],1)],1),_c('v-card-title',{staticClass:"d-flex flex-column font-weight-bold primary--text"},[_vm._v(" Create an account ")]),_c('validation-observer',{ref:"register",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","autocomplete":"first_name","error-messages":errors,"hint":"Here you should write your first name","persistent-placeholder":"","placeholder":"First name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("First name")])]},proxy:true}],null,true),model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","autocomplete":"last_name","error-messages":errors,"hint":"Here you should write your last name","persistent-placeholder":"","placeholder":"Last name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("Last name")])]},proxy:true}],null,true),model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","autocomplete":"email","error-messages":errors,"hint":"Here you should write your e-mail","persistent-placeholder":"","placeholder":"E-mail"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("E-mail Address")])]},proxy:true}],null,true),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Sex","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-n1",attrs:{"items":_vm.sex,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("Choose your sex")])]},proxy:true}],null,true),model:{value:(_vm.form.sex),callback:function ($$v) {_vm.$set(_vm.form, "sex", $$v)},expression:"form.sex"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"current-password","error-messages":errors,"hint":"Password could be restored","persistent-placeholder":"","placeholder":"Password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("Password")])]},proxy:true}],null,true),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password confirmation","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"password_confirmation","error-messages":errors,"persistent-placeholder":"","placeholder":"Password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("Repeat your password")])]},proxy:true}],null,true),model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1),_c('v-card-text',{staticClass:"mt-n6"},[_c('small',[_vm._v("By signing up you agree with the")]),_c('router-link',{staticClass:"px-0 text-capitalize d-inline",staticStyle:{"text-decoration":"none"},attrs:{"to":"/terms-of-service","color":"primary"}},[_c('small',[_vm._v(" Terms of Service ")])]),_c('small',[_vm._v("and")]),_c('router-link',{staticClass:"px-0 text-capitalize d-inline",staticStyle:{"text-decoration":"none"},attrs:{"to":"privacy-policy","color":"primary"}},[_c('small',[_vm._v(" Privacy Policy ")])])],1),_c('v-card-actions',{staticClass:"d-flex flex-column justify-center mb-16"},[_c('v-btn',{attrs:{"min-width":"85%","max-width":"95%","height":"49","rounded":"","type":"submit","color":"primary"}},[_vm._v("Request Membership")]),_c('div',{staticClass:"mt-3",staticStyle:{"z-index":"1"}},[_c('small',[_vm._v("Do you have account already? ")]),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'login' }}},[_c('small',[_vm._v("Sign In")])])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }