<template>
  <v-row class="ma-0 pa-0 fill-height">
    <v-col cols="10" lg="5" xl="3"  class="d-flex align-center mx-auto fill-height">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <v-card width="100%" class="pa-8">
            <v-card-title class="d-flex flex-column font-weight-bold">
              <router-link to="/">
              <v-img src="@/assets/logo.svg" alt="MegaBusiness Logo" max-width="206.36"></v-img>
              </router-link>
            </v-card-title>
            <v-card-title class="d-flex flex-column font-weight-bold primary--text">
              Create an account
            </v-card-title>
            <validation-observer ref="register" v-slot="{ passes }">
              <v-form @submit.prevent="passes(submit)">
                <v-card-text>
                  <validation-provider name="First name" rules="required" v-slot="{ errors }">
                    <v-text-field type="text"
                                  autocomplete="first_name"
                                  :error-messages="errors"
                                  v-model="form.first_name"
                                  hint="Here you should write your first name"
                                  persistent-placeholder
                                  placeholder="First name">
                      <template v-slot:label>
                        <label class="font-weight-bold">First name</label>
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider name="Last name" rules="required" v-slot="{ errors }">
                    <v-text-field type="text"
                                  autocomplete="last_name"
                                  :error-messages="errors"
                                  v-model="form.last_name"
                                  hint="Here you should write your last name"
                                  persistent-placeholder
                                  placeholder="Last name">
                      <template v-slot:label>
                        <label class="font-weight-bold">Last name</label>
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider name="E-mail" rules="required|email" v-slot="{ errors }">
                    <v-text-field type="email"
                                  autocomplete="email"
                                  :error-messages="errors"
                                  v-model="form.email"
                                  hint="Here you should write your e-mail"
                                  persistent-placeholder
                                  placeholder="E-mail">
                      <template v-slot:label>
                        <label class="font-weight-bold">E-mail Address</label>
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider name="Sex" rules="required" v-slot="{ errors }">
                    <v-select v-model="form.sex"
                              class="mt-n1"
                              :items="sex"
                              :error-messages="errors">
                      <template v-slot:label>
                        <label class="font-weight-bold">Choose your sex</label>
                      </template>
                    </v-select>
                  </validation-provider>
                  <validation-provider name="password" rules="required|confirmed:confirmation" v-slot="{ errors }">
                    <v-text-field type="password"
                                  autocomplete="current-password"
                                  :error-messages="errors"
                                  v-model="form.password"
                                  hint="Password could be restored"
                                  persistent-placeholder
                                  placeholder="Password">
                      <template v-slot:label>
                        <label class="font-weight-bold">Password</label>
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider name="password confirmation" vid="confirmation" v-slot="{ errors }">
                    <v-text-field type="password"
                                  autocomplete="password_confirmation"
                                  :error-messages="errors"
                                  v-model="form.password_confirmation"
                                  persistent-placeholder
                                  placeholder="Password">
                      <template v-slot:label>
                        <label class="font-weight-bold">Repeat your password</label>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-card-text>
                <v-card-text class="mt-n6">
                  <small>By signing up you agree with the</small>
                  <router-link style="text-decoration: none"
                    to="/terms-of-service" color="primary" class="px-0 text-capitalize d-inline">
                    <small>&nbsp;Terms of Service&nbsp;</small>
                  </router-link>
                  <small>and</small>
                  <router-link style="text-decoration: none"
                      to="privacy-policy" color="primary" class="px-0 text-capitalize d-inline"><small>&nbsp;Privacy Policy&nbsp;</small>
                  </router-link>
                </v-card-text>
                <v-card-actions class="d-flex flex-column justify-center mb-16">
                  <v-btn min-width="85%"
                         max-width="95%"
                         height="49" rounded type="submit" color="primary">Request Membership</v-btn>
                  <div class="mt-3" style="z-index: 1">
                    <small>Do you have account already?&nbsp;</small>
                    <router-link style="text-decoration: none" :to="{ name: 'login' }"><small>Sign In</small></router-link>
                  </div>
                </v-card-actions>
              </v-form>
            </validation-observer>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Registration",
  data () {
    return {
      form: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        sex: null
      },
      sex: [
        {
          text: 'Female',
          value: 'female'
        },
        {
          text: 'Male',
          value: 'male'
        },
        {
          text: 'Other',
          value: 'other'
        }
      ]
    }
  },
  methods: {
    submit() {
      this.$http.post(`${process.env.VUE_APP_API_URL}/request-membership`, this.form)
      .then(() => {
        this.$refs.register.reset()
        this.$worker.resetForm(this.form)
      })
    }
  }
}
</script>

<style scoped lang="scss">
canvas {
  width: 100%;
  height: 100%;
}
</style>
